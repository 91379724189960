import revive_payload_client_4sVQNw7RlN from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/mj_client/releases/20240912052859/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/mj_client/releases/20240912052859/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/var/www/mj_client/releases/20240912052859/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/var/www/mj_client/releases/20240912052859/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/www/mj_client/releases/20240912052859/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/www/mj_client/releases/20240912052859/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import bug_snag_Gk3MG8hdoc from "/var/www/mj_client/releases/20240912052859/plugins/bug-snag.ts";
import click_outside_oytHm5ka8v from "/var/www/mj_client/releases/20240912052859/plugins/click-outside.js";
import error_handler_VFH3VvK7yG from "/var/www/mj_client/releases/20240912052859/plugins/error-handler.js";
import firebase_client_nS52P0yitD from "/var/www/mj_client/releases/20240912052859/plugins/firebase.client.js";
import font_awesome_bPqvtqUEQ4 from "/var/www/mj_client/releases/20240912052859/plugins/font-awesome.js";
import global_mixins_FeoDNy7nGB from "/var/www/mj_client/releases/20240912052859/plugins/global-mixins.js";
import navigation_repaint_client_hX5MboUDmw from "/var/www/mj_client/releases/20240912052859/plugins/navigation-repaint.client.ts";
import pinia_Uphuq97G1L from "/var/www/mj_client/releases/20240912052859/plugins/pinia.js";
import tags_client_hfn6mM8Rf5 from "/var/www/mj_client/releases/20240912052859/plugins/tags.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  nuxt_plugin_6wEQMY3tee,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  bug_snag_Gk3MG8hdoc,
  click_outside_oytHm5ka8v,
  error_handler_VFH3VvK7yG,
  firebase_client_nS52P0yitD,
  font_awesome_bPqvtqUEQ4,
  global_mixins_FeoDNy7nGB,
  navigation_repaint_client_hX5MboUDmw,
  pinia_Uphuq97G1L,
  tags_client_hfn6mM8Rf5
]